import React, { Component } from 'react';
import { hasWebp } from '../../utils';

import styles from './styles.module.scss';

class Header extends Component {
  render() {
    const {
      bgWebp,
      bgJpg,
      title,
      bgColor,
    } = this.props;
    return (
      <header
        id="header-block"
        className={styles.header}
        style={!bgColor ? {
          backgroundImage: `url(${hasWebp && bgWebp ? bgWebp : bgJpg})`
        } : {
          backgroundColor: bgColor,
        }}
      >
        <div className={styles.headerWrapper}>
        <h1 className={styles.title}>
          {title}
        </h1>
        </div>
        
        <div className={styles.headDarkBg}></div>
      </header>
    );
  }
}

export default Header;
