import React, { Component } from 'react';
import AOS from 'aos'; 

import resizeDimension from '../../../decorators/resizeDimension';
import Slider from '../../../components/Slider';

import review1 from './imgs/foto1.jpg';
import review2 from './imgs/foto2.jpg';
import review3 from './imgs/foto3.jpg';
import doPosle1 from './imgs/1.png';
import doPosle2 from './imgs/2.png';
import doPosle3 from './imgs/3.png';
import doPosle4 from './imgs/4.png';
import doPosle6 from './imgs/6.png';
import doPosle7 from './imgs/7.png';
import doPosle8 from './imgs/8.png';
import doPosle9 from './imgs/9.png';
import doPosle10 from './imgs/10.png';
import doPosle11 from './imgs/11.png';
import doPosle12 from './imgs/12.png';
import doPosle13 from './imgs/13.png';
import doPosle14 from './imgs/14.png';
import doPosle15 from './imgs/15.jpg';
import doPosle16 from './imgs/16.jpg';
import doPosle17 from './imgs/17.jpg';
import doPosle18 from './imgs/18.jpg';
import doPosle19 from './imgs/19.jpg';

import stylesModal from '../../Home/Head/styles.module.scss';

class Reviews extends Component {
  intervalID = 0;

  constructor(props, context) { 
    super(props, context); 

    this.state = {
      zoomImg: null,
      slides: [
        {
          id: 1,
          urls: review1,
          desc: (
            <span>
              Процесс восстановления заднего крыла под локальный окрас.
            </span>
          )
        },
        {
          id: 2,
          urls: review2,
          desc: (
            <span role="img">
              Машинка готова к локальному окрасу💪. Что от нас требовалось мы сделали и передали маляру.
            </span>
          )
        },
        {
          id: 3,
          urls: review3,
          desc: (
            <span>
              В процессе ремонта заднего крыла на Mitsubishi Pajero.
            </span>
          )
        },
        {
          id: 4,
          urls: doPosle1,
          desc: null,
        },
        {
          id: 5,
          urls: doPosle2,
          desc: null,
        },
        {
          id: 6,
          urls: doPosle3,
          desc: null,
        },
        {
          id: 7,
          urls: doPosle4,
          desc: null,
        },
        {
          id: 9,
          urls: doPosle6,
          desc: null,
        },
        {
          id: 10,
          urls: doPosle7,
          desc: null,
        },
        {
          id: 11,
          urls: doPosle8,
          desc: null,
        },
        {
          id: 12,
          urls: doPosle9,
          desc: null,
        },
        {
          id: 13,
          urls: doPosle10,
          desc: null,
        },
        {
          id: 14,
          urls: doPosle11,
          desc: null,
        },
        {
          id: 15,
          urls: doPosle12,
          desc: null,
        },
        {
          id: 16,
          urls: doPosle13,
          desc: null,
        },
        {
          id: 17,
          urls: doPosle14,
          desc: null,
        },
        {
          id: 18,
          urls: doPosle15,
          desc: null,
        },
        {
          id: 19,
          urls: doPosle16,
          desc: null,
        },
        {
          id: 20,
          urls: doPosle17,
          desc: null,
        },
        {
          id: 21,
          urls: doPosle18,
          desc: null,
        },
        {
          id: 22,
          urls: doPosle19,
          desc: null,
        },
      ],
      leftSLide: 0,
      activeSlide: 1,
      rightSlide: 2,
    }

    AOS.init(); 
  } 
  componentDidMount() {
    this.playSlide();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  handleZoomIn = img =>
    this.setState({
      zoomImg: img,
    });

  handleZoomOut = () =>
    this.setState({
      zoomImg: null,
    });

  handleClickNav = slide => {
    const {
      slides,
    } = this.state;


    clearInterval(this.intervalID);
    
    const len = slides.length - 1;
    const rightSlide = slide < len ? slide + 1 : 0;
    const leftSLide = slide > 0 ? slide - 1 : len;

    this.setState({
      activeSlide: slide,
      leftSLide,
      rightSlide,
    });

    setTimeout(this.playSlide, 2000);
  }

  playSlide = () => {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(this.handleClickRightSlide, 5000);
  }
  pauseSlide = () => {
    clearInterval(this.intervalID);
  }

  handleClickLeftSlide = () => {
    this.handleClickNav(this.state.leftSLide)
  };

  handleClickRightSlide = () => this.handleClickNav(this.state.rightSlide)

  renderZoomImg = () => {
    const { zoomImg } = this.state;
    return (
      <div className={zoomImg ? stylesModal.modalFullScreenIsOpen : stylesModal.modalFullScreen}>
        <div
          className={stylesModal.modalCloseBtn}
          onClick={this.handleZoomOut}
        >
          <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className="style-scope yt-icon"><g className="style-scope yt-icon">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" className="style-scope yt-icon" />
          </g></svg>
        </div>
        <div
          style={{
            backgroundSize: 'contain',
            backgroundImage: `url(${zoomImg.urls})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    );
  }

  render() {
    const {
      slides,
      activeSlide,
      leftSLide,
      rightSlide,
      zoomImg
    } = this.state;

    return (
      <Slider
        slides={slides}
        activeSlide={activeSlide}
        leftSLide={leftSLide}
        rightSlide={rightSlide}
        zoomImg={zoomImg}
        pauseSlide={this.pauseSlide}
        playSlide={this.playSlide}
        handleClickLeftSlide={this.handleClickLeftSlide}
        handleClickRightSlide={this.handleClickRightSlide}
        handleZoomIn={this.handleZoomIn}
        renderZoomImg={this.renderZoomImg}
      />
    );
  }
}

export default resizeDimension()(Reviews);
