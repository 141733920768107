import React, { Component } from 'react';
import { loadReCaptcha } from 'react-recaptcha-v3';

import resizeDimension from '../../../decorators/resizeDimension';


import Form from './Form';

import styles from './styles.module.scss';



class LearningEstimate extends Component {
  componentDidMount() {
    loadReCaptcha('6Ld3xa4UAAAAAPJpVGfwynLZ55nf25ZeZx7QFPo7');
  }

  componentWillReceiveProps (){ 
    // AOS.refresh(); 
  }

  render() {
    const {
      phone,
      name,
      tarif,
      checkPolicy,
      handleSubmit,
      handleChange,
      handleCheck,
      isSuccessSend,
    } = this.props;

    return (
      <div className={styles.fullContainer}>
        <div className={styles.head}>
          <div className={styles.headWrapper}>
            <h3 className={styles.headTitle}>Оставить заявку на обучение</h3>
          </div>
        </div>

        <div className={styles.estimateTopForm}>

          <Form
            goToBack={this.goToBack}
            handleSubmit={handleSubmit}
            isSuccessSend={isSuccessSend}
            handleChange={handleChange}
            handleCheck={handleCheck}
            phone={phone}
            name={name}
            tarif={tarif}
            checkPolicy={checkPolicy}
          />
        </div>

        <div id="estimateWrapper" className={styles.estimateWrapper} />

      </div>
    );
  }
}

export default resizeDimension()(LearningEstimate);
