import React from 'react';

import Img from '../../../../components/Img';

import styles from '../styles.module.scss';

const SlideItem = ({
  isActive,
  isRightSlide,
  urls,
  name,
  position,
  desc,
  linkTitle,
  link,
  style,
  handleClick = () => false,
}) => (
  <div
    onClick={handleClick}
    className={isActive ? styles.slideActiveItem : isRightSlide ? styles.slideRightItem : styles.slideItem}
  >
    <div className={styles.slidePhoto}>
        <Img
          url={urls[0]}
          urlWebp={urls[1]}
          width={'100%'}
          height={'100%'}
          style={style}
        />
    </div>
    <div className={styles.slideDesc}>
        <span className="name">{name}</span>
        {position && <span className="position">{position}</span>}
        {link && <a href={link} target="_blank" rel="noopener noreferrer" className="link">{linkTitle}</a>}

        <p>{desc}</p>
    </div>
  </div>
);

export default SlideItem;
