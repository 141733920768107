import React, { Component } from 'react';
import AOS from 'aos'; 

import CtaBtns from '../../../components/CtaBtns';

import styles from './styles.module.scss';

class Reviews extends Component {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  render() {

    return (
      <div className={styles.containerFluid}>
        <h3 className={styles.head}>
          Почему удаление вмятин без покраски выгоднее<br />обычных способов ремонта?
        </h3>
        
        <div className={styles.body}>
          <div className={styles.container}>
            Мы используем самые <strong>современные методы и инструменты </strong><br />
            для <strong>ремонта кузова</strong>, которые обеспечивают <br />
            хороший внешний вид вашего автомобиля. <br />
            Вам не приходится красить машину, <br />
            что снижает цену на рынке при продаже.

          </div>

          <CtaBtns className={styles.ctaBtns} />
        </div>

      </div>
    );
  }
}

export default Reviews;
