import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';

import styles from './styles.scss';

import {
  hasWebp
} from '../../utils';

class Img extends Component {
  constructor() {
    super();

    this.state = {
      isEnter: null,
    };
  }

  handleEnter = () =>
    this.setState({ isEnter: true })

  render() {
    const {
      url,
      urlWebp,
      alt,
      width,
      height,
      ...props
    } = this.props;
    const {
      isEnter,
    } = this.state;

    return(
      <Waypoint onEnter={this.handleEnter}>
        {isEnter ? (hasWebp() && urlWebp ? (
          <img
            alt={alt}
            src={urlWebp}
            {...props}
          />
        ) : (
          <img
            alt={alt}
            src={url}
            {...props}
          />
        )): (
          <svg
            className={styles.imgDefault}
            width={width}
            height={height}
          />
        )}
      </Waypoint>
    );
  }
}

export default Img;
      