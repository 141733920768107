import review2 from "./imgs/review-2.jpg";
import review1 from "./imgs/review-1.jpg";
import review3 from "./imgs/review-3.jpg";
import review4 from "./imgs/review-4.jpg";
import review5 from "./imgs/review-5.jpg";
import review6 from "./imgs/review-6.jpg";
import React from "react";

export default [{
    id: 1,
    urls: [review2],
    name: "Амирханов Ильгиз Куттусович",
    position: "Фитнес директор",
    desc: (
        <span>
              Сразу скажу, я знаю Ильяса лично! И именно поэтому могу сказать честно, что он и его команда отличаются на рынке авто услуг тем, что делают они качественно. Для них качество это не просто слово, это основная идея, на котором построен их бизнес.
              <br/><br/>
              Серьезная контора, с серьезным подходом к каждому автомобилю. Ваш случай будет рассмотрен индивидуально, и ваша машинка получит персональное и лучше решение.
              <br/><br/>
              Только профессионал может предложить несколько вариантов решения вашей задачи с разным бюджетом. Тут вам скажут все как есть и дадут на выбор способ решения вашей проблемы. Обратившись к ним, можно быть уверенным, что вы получите лучше, экспертное мнение опытных специалистов.
            </span>
    )
},
    {
        id: 2,
        style: {
            maxWidth: 300,
        },
        urls: [review1],
        name: "Абдразаков Адель Шамилевич",
        position: "Генеральный директор ООО «Бизнес»",
        linkTitle: '@studiya_localnogo_remonta',
        link: 'https://instagram.com/studiya_localnogo_remonta',
        desc: (
            <span>
              Работаю в сфере более 15 лет. Особое внимание сейчас уделяем локальному ремонту. По статистике, большинство автомобилей имеют незначительные дефекты кузова в виде потертостей, сколов и царапин. Такие манипуляции не требуют много времени и больших финансовых затрат.
              <br/>
              <br/>
              Работаем со студией SABR уже несколько лет потому что они лучшие в сфере ремонта вмятин без покраски, доступные цены, работа выполняется профессиональными мастерами, ремонт деталей любой сложности, что для меня как для мастера очень важно. Так как это минимальное нанесение шпаклевки.
            </span>
        )
    },
    {
        id: 3,
        urls: [review3],
        name: "Александр Сафронов",
        position: "фотограф",
        linkTitle: '@aleksandersafronov',
        link: 'https://www.instagram.com/aleksandersafronov/',
        desc: (
            <span>
              Я редко пишу отзывы, но здесь просто не могу не поделиться, потому что это настоящая находка! Ребята отремонтировали мне две машины. На степвее был скол на капоте, на хэндае помят капот, при том памят очень прилично.
              <br/><br/>
              А сейчас у меня две абсолютно целые, новые машины. И за это я благодарен ребятам. Все очень чётко, быстро, профессионально и с адекватным объяснением.
              <br/><br/>
              Цена конечно была так же очень важным фактором.  В общем я безгранично доволен и благодарен за профессиональную работу и теперь всем их советую.
            </span>
        )
    },
    {
        id: 4,
        urls: [review4],
        name: "Закиров Руслан Талгатович",
        position: "",
        desc: (
            <span>
              Спасибо большое автосервису SABR. Сделали невозможное. Выпрямили крыло. Другие сервисы предлагали только замену. Огромное спасибо. Всем советую. Ребята знают свою работу!
            </span>
        )
    },
    {
        id: 5,
        urls: [review5],
        name: "Владислав Ромашкин",
        desc: (
            <span>
              Сделали мне бампер, я считаю, очень хорошо, причем, ребята не поленились сделать мелкую работу, которую в принципе можно было и и не делать. Сервис, короче, не из той серии «ай и так сойдет»
            </span>
        )
    },
    {
        id: 6,
        urls: [review6],
        name: "Наиль Хайруллин",
        position: "",
        desc: (
            <span>
              Парни молодцы, сделали все высшем уровне и в кратчайшие сроки, понравилось приятное соотношение цены и качества, дружелюбный, общительный персонал, буду рекомендовать друзьям!
            </span>
        )
    },
];