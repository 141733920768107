import React, { Component } from 'react';

import bgJpg from './imgs/header-bg.jpg';
import bgWebp from './imgs/header-bg.webp';

import HeadComponent from '../../../components/Head';

class Header extends Component {
  render() {
    return (
      <HeadComponent
        bgJpg={bgJpg}
        bgWebp={bgWebp}
        title="Наши контакты"
      />
    );
  }
}

export default Header;
