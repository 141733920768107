import React, { Component } from 'react';

import styles from './styles.module.scss';

import iconMap from './imgs/iconMap.png';

class Header extends Component {
  componentDidMount() {
    window.ymaps.ready(function () {
      const myMap = new window.ymaps.Map('map', {
              center: [55.797230, 49.210490],
              zoom: 17
          });
  
      const myPlacemark = new window.ymaps.Placemark(myMap.getCenter(), {
              hintContent: 'Ремонт вмятин без покраски - SABR',
          }, {
              iconLayout: 'default#image',
              iconImageHref: iconMap,
              iconImageSize: [90, 90],
              iconImageOffset: [-40, -80]
          });
  
      myMap.geoObjects
          .add(myPlacemark);
    });
  }

  render() {
    return (
      <div className={styles.map}>
        <div className={styles.desc}>
          <div>
            <h3 className={styles.title}>График работы:</h3>
            <div className={styles.text}>
            С 9:00 ДО 19:00<br />
            С&nbsp;ПОНЕДЕЛЬНИКА ПО&nbsp;ПЯТНИЦУ
            </div>
          </div>
          <div>
            <h3 className={styles.title}>Эл. почта:</h3>
            <div className={styles.text}>
            <a href="mailto:info@avtoservis-sabr.ru?subject=Вопрос с сайта">info@avtoservis-sabr.ru</a>
            </div>
          </div>
          <div>
            <h3 className={styles.title}>Телефон:</h3>
            <div className={styles.text}>
            <a href="tel:+79872289904">+7 (987) 228-99-04</a>
            </div>
          </div>
          
          
        </div>
        <div className={styles.mapWrapper}>
          <div id="map" style={{ width: '100%', height: 400 }}>
            
          </div>
        </div>
      </div>
    );
  }
}

export default Header;
