import React, { Component } from 'react';
import AOS from 'aos'; 

import resizeDimension from '../../../decorators/resizeDimension';

import Slide from './SlideItem';
import initialSlides from './slides';
import styles from './styles.module.scss';

import arrowIc from './keyboard-right-arrow-button.svg';

class Reviews extends Component {
  intervalID = 0;

  constructor(props, context) { 
    super(props, context); 

    this.state = {
      slides: initialSlides,
      leftSLide: 0,
      activeSlide: 1,
      rightSlide: 2,
    }
  } 
  componentDidMount() {
    this.playSlide();
    AOS.init();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  handleClickNav = slide => {
    const {
      slides,
    } = this.state;
    
    clearInterval(this.intervalID);

    const len = slides.length - 1;
    const rightSlide = slide < len ? slide + 1 : 0;
    const leftSLide = slide > 0 ? slide - 1 : len;

    this.setState({
      activeSlide: slide,
      leftSLide,
      rightSlide,
    });
    setTimeout(this.playSlide, 2000);
  }

  playSlide = () => {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(this.handleClickRightSlide, 10000);
  }
  pauseSlide = () => {
    clearInterval(this.intervalID);
  }

  handleClickLeftSlide = () => this.handleClickNav(this.state.leftSLide)

  handleClickRightSlide = () => this.handleClickNav(this.state.rightSlide)

  render() {
    const {
      slides,
      activeSlide,
      leftSLide,
      rightSlide
    } = this.state;

    const { innerHeight, innerWidth } = this.props;


    return (
      <div className={styles.containerFluid}>
        <div className={styles.head}>
          Нам доверяют
        </div>
        <div className={styles.body} style={{ height: innerWidth > 1280 ? innerHeight : 'auto' }}>

          <div
            className={styles.slides}
            data-aos="zoom-in"
            onMouseEnter={this.pauseSlide}
            onMouseLeave={this.playSlide}
          >
            {
              slides.map((item, index) => [activeSlide, leftSLide, rightSlide].indexOf(index) !== -1 && (
                <Slide
                  key={item.id}
                  style={item.style}
                  isActive={index === activeSlide}
                  isRightSlide={rightSlide === index}
                  handleClick={index === leftSLide ? this.handleClickLeftSlide : index === rightSlide ? this.handleClickRightSlide : null}
                  {...slides[index]}
                />
              ))
            }
            <button className={styles.slidesNavArrow} onClick={this.handleClickRightSlide}>
              <img src={arrowIc} alt="" />
            </button>
            <button className={styles.slidesNavArrowLeft} onClick={this.handleClickLeftSlide}>
              <img src={arrowIc} alt="" />
            </button>
            <ul className={styles.slidesNav}>
            {slides.map((i, k) => (
              <li key={i.id} onClick={() => this.handleClickNav(k)} className={k === activeSlide ? 'is-active' : ''} />
            ))}
            </ul>
            
          </div>

        </div>
        
      </div>
    );
  }
}

export default resizeDimension()(Reviews);
