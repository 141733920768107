import React, { Component } from 'react';
import AOS from 'aos'; 
import { Waypoint } from 'react-waypoint';

import styles from './styles.module.scss';

class Description extends Component {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 
  
  render() {
    return (
      <Waypoint onEnter={this.handleEnter}>
        <div className={styles.full}>

          <div className={styles.row}>
            <div
              data-aos="fade-left"
              className={styles.car}
            >
              <div className="icon" />
              <span className="number">> 1200</span>
              <span className="title">машин <br/>в год</span>
            </div>
            <div
              data-aos="fade-left"
              className={styles.customer}
              data-aos-delay="200"
            >
              <div className="icon" />
              <div className="icon" />
              <div className="icon" />
              <span className="number">99%</span>
              <span className="title">довольных <br/>покупателей</span>
            </div>
          </div>
          <div className={styles.row}>
            <div data-aos="fade-right" data-aos-delay="200" className={styles.service}>
              <div className="icon" />
              <span className="title">Вежливый <br/>персонал</span>
            </div>

            <div data-aos="fade-right" data-aos-delay="400" className={styles.assessment}>
              <div className="icon" />
              <span className="number">> 200</span>
              <span className="title">машин <br/>в год</span>
            </div>
          </div>

        </div>
        
      </Waypoint>
    );
  }
}

export default Description;
