import React from 'react';
import Modal from '../Modal';
import Slide from './SlideItem';

import styles from './styles.module.scss';
import arrowIc from './keyboard-right-arrow-button.svg';

const Slider = ({
  slides,
  activeSlide,
  leftSLide,
  rightSlide,
  zoomImg,
  pauseSlide,
  playSlide,
  handleClickLeftSlide,
  handleClickRightSlide,
  handleZoomIn,
  renderZoomImg,
  sliderBodyStyle,
}) => (
  <div className={styles.containerFluid}>
        <div className={sliderBodyStyle || styles.body}>

          <div
            className={styles.slides}
            data-aos="zoom-in"
            onMouseEnter={pauseSlide}
            onMouseLeave={playSlide}
          >
            {
              slides.map((item, index) => [activeSlide, leftSLide, rightSlide].indexOf(index) !== -1 && (
                <Slide
                  key={item.id}
                  isActive={index === activeSlide}
                  isRightSlide={rightSlide === index}
                  handleClick={index === leftSLide ? handleClickLeftSlide : index === rightSlide ? handleClickRightSlide : () => handleZoomIn(item)}
                  {...slides[index]}
                />
              ))
            }
            <button className={styles.slidesNavArrow} onClick={handleClickRightSlide}>
              <img src={arrowIc} alt="" />
            </button>
            <button className={styles.slidesNavArrowLeft} onClick={handleClickLeftSlide}>
              <img src={arrowIc} alt="" />
            </button>
            {/* <ul className={styles.slidesNav}>
            {slides.map((i, k) => (
              <li key={i.id} onClick={() => handleClickNav(k)} className={k === activeSlide ? 'is-active' : ''} />
            ))}
            </ul> */}
            
          </div>

        </div>
        
        {zoomImg && <Modal>
          {renderZoomImg()}
        </Modal>}
      </div>
);

export default Slider;
