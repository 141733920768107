import React from 'react';

import styles from './styles.module.scss';

const Logo = ({ isSmall }) => (
  <div className={isSmall ? styles.wrapperSmall : styles.wrapper}>
    <div className={styles.icon} />
    <div className={styles.texts}>
      <h2 className={styles.title}>SABR</h2>
      <h1>
        Ремонт вмятин <br />
        Без покраски
      </h1>
    </div>
  </div>
);

export default Logo;
