import React, { Component } from "react";
import {
  BrowserRouter,
  Route,
  Switch
} from "react-router-dom";

import ScrollToTop from '../../components/ScrollToTop';

import Home from '../Home';
import Contact from '../Contact';
import Gallery from '../Gallery';
import Services from '../Services';
import OurTeam from '../OurTeam';
import Learning from '../Learning';

class App extends Component {
  componentWillMount() {
    const {
      hasWebp
    } = this.props;

    document.getElementsByTagName( 'html' )[0].className = (hasWebp ? 'has-webp' : 'no-webp');
  }

  render() {

    return (
      <BrowserRouter>
        <ScrollToTop>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/learning" exact component={Learning} />
              <Route path="/contacts" exact component={Contact} />
              <Route path="/gallery" exact component={Gallery} />
              <Route path="/services" exact component={Services} />
              <Route path="/our-team" exact component={OurTeam} />
            </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
