import React from 'react';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';

const CtaBtns = () => (
  <div className={styles.ctaBtns}>
            <HashLink smooth to="/#estimate" onClick={() => {
              if (window.reachGoal) window.reachGoal('click_free_estimate');
            }} className={styles.btnRight}><span>0 р.</span><span>Бесплатная оценка</span></HashLink>
            <button className={styles.btnLeft} onClick={() => {
              if (window.reachGoal) window.reachGoal('whatsapp_call');
              window.open('https://wa.me/79872289904','_blank');
            }} rel="noopener noreferrer">
              Написать в WhatsApp
            </button>
          </div>
);

export default CtaBtns;
