import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

import NavSmall from '../Navs';
import CtaBtns from '../../components/CtaBtns';
import Footer from '../../containers/Footer';

import Head from './Head';
import Info from '../Home/Info';

import styles from './styles.module.scss';


class Services extends Component {
  componentDidMount() {
    if (window.pageView) window.pageView('/services');
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" content="Ремонт вмятин без покраски, установка автодоводчиков" />
          <meta name="keywords" content="список услуг, услуги автосервиса, ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина" />
          <title>Услуги | Автосервис "SABR"</title>
        </Helmet>
        <NavSmall />

        <Head />
    
        <div className={styles.mainWrapper}>
          <Info noTitle />
        </div>

        <div className={styles.ctaBtns}>
          <CtaBtns />
        </div>
        
    
        <Footer />
      </div>
    );
  }
}

export default Services;
