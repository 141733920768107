import React, { Component } from 'react';
import AOS from 'aos'; 

import video from './imgs/222.mp4';
import img1 from './imgs/img1.jpg';
import styles from './styles.module.scss';

class Description extends Component {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  render() {
    const { noTitle } = this.props;
    return (
      <div className={styles.container}>
        {!noTitle && <h4 className={styles.head}>Наши услуги</h4>}

        <div className={styles.row}>
          <div>
            <div className={styles.title}>Ремонт вмятин без покраски</div>
            <div className={styles.text}>
            PDR тех&shy;но&shy;ло&shy;гия (Paintless dent repair&nbsp;/ paintless dent removal) – бес&shy;по&shy;кра&shy;соч&shy;ный ремонт вмятины/беспокрасочное уда&shy;ле&shy;ние вмя&shy;ти&shy;ны, пред&shy;став&shy;ля&shy;ет собой метод выправ&shy;ле&shy;ния&nbsp;незна&shy;чи&shy;тель&shy;ных вмя&shy;тин с кузо&shy;ва авто&shy;мо&shy;би&shy;ля. По-немец&shy;ки эта тех&shy;но&shy;ло&shy;гия назы&shy;ва&shy;ет&shy;ся&nbsp;DOL — (Dellen Entfernung ohne Lackierung). При помо&shy;щи PDR может быть отре&shy;мон&shy;ти&shy;ро&shy;ва&shy;но мно&shy;же&shy;ство раз&shy;но&shy;вид&shy;но&shy;стей повре&shy;жде&shy;ний, при усло&shy;вии, что крас&shy;ка не повре&shy;жде&shy;на. PDR может при&shy;ме&shy;нять&shy;ся как на сталь&shy;ных, так и на алю&shy;ми&shy;ни&shy;е&shy;вых пане&shy;лях кузо&shy;ва.
            </div>
          </div>
          <div className={styles.media}>
            <img src={img1} 
            width="100%" alt="Ремонт вмятин без покраски в Казани"/>
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.media}>
          <video
            src={video}
            width="100%"
            type="video/mp4"
            loop
            muted
            controls
          />
          </div>
          <div>
            <div className={styles.title}>Установка доводчиков</div>
            <div className={styles.text}>
            Часто такое возникает в легковых автомобилях при закрытии задних дверей или в громоздких внедорожниках. Для того, чтоб избавится от такой неприятной вещи, разработали автомобильные доводчики дверей, для плотного закрытия дверей.
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Description;
