import React, { Component } from 'react';

const resizeDimension = () => (WrappedComponent) => {
  return class extends Component {
    constructor() {
      super();

      this.state = {
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      };
    }

    componentDidMount() {
      window.addEventListener('resize', this.updateDimensions);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.updateDimensions);
    }

    updateDimensions = () => {
      this.setState({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    }

    render() {
      const {
        ...props
      } = this.props;
      const {
        innerWidth,
        innerHeight,
      } = this.state;
      return (
        <WrappedComponent
          innerHeight={innerHeight}
          innerWidth={innerWidth}
          {...props}
        />
      );
    }

  }
};

export default resizeDimension;
