import React, { Component } from 'react';
import AOS from 'aos'; 

import resizeDimension from '../../../../decorators/resizeDimension';
import Slider from '../../../../components/Slider';

// import img1 from './imgs/img1.png';
import img2 from './imgs/img2-2.jpg';
import img3 from './imgs/img4-2.jpg';
import img4 from './imgs/img6-2.jpg';
import img5 from './imgs/img7.png';
import img6 from './imgs/img8.png';

import stylesModal from '../../../Home/Head/styles.module.scss';

import styles from './styles.module.scss'

class Reviews extends Component {
  intervalID = 0;

  constructor(props, context) { 
    super(props, context); 

    this.state = {
      zoomImg: null,
      slides: [
        {
          id: 24,
          urls: img2,
          desc: null,
        },
        {
          id: 25,
          urls: img3,
          desc: null,
        },
        {
          id: 26,
          urls: img4,
          desc: null,
        },
        {
          id: 27,
          urls: img5,
          desc: null,
        },
        {
          id: 28,
          urls: img6,
          desc: null,
        },
      ],
      leftSLide: 0,
      activeSlide: 1,
      rightSlide: 2,
    }

    AOS.init(); 
  } 
  componentDidMount() {
    this.playSlide();
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  handleZoomIn = img =>
    this.setState({
      zoomImg: img,
    });

  handleZoomOut = () =>
    this.setState({
      zoomImg: null,
    });

  handleClickNav = slide => {
    const {
      slides,
    } = this.state;


    clearInterval(this.intervalID);
    
    const len = slides.length - 1;
    const rightSlide = slide < len ? slide + 1 : 0;
    const leftSLide = slide > 0 ? slide - 1 : len;

    this.setState({
      activeSlide: slide,
      leftSLide,
      rightSlide,
    });

    setTimeout(this.playSlide, 2000);
  }

  playSlide = () => {
    clearInterval(this.intervalID);
    this.intervalID = setInterval(this.handleClickRightSlide, 5000);
  }
  pauseSlide = () => {
    clearInterval(this.intervalID);
  }

  handleClickLeftSlide = () => {
    this.handleClickNav(this.state.leftSLide)
  };

  handleClickRightSlide = () => this.handleClickNav(this.state.rightSlide)

  renderZoomImg = () => {
    const { zoomImg } = this.state;
    return (
      <div className={zoomImg ? stylesModal.modalFullScreenIsOpen : stylesModal.modalFullScreen}>
        <div
          className={stylesModal.modalCloseBtn}
          onClick={this.handleZoomOut}
        >
          <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className="style-scope yt-icon"><g className="style-scope yt-icon">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" className="style-scope yt-icon" />
          </g></svg>
        </div>
        <div
          style={{
            backgroundSize: 'contain',
            backgroundImage: `url(${zoomImg.urls})`,
            backgroundPosition: 'center center',
            backgroundRepeat: 'no-repeat',
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    );
  }

  render() {
    const {
      slides,
      activeSlide,
      leftSLide,
      rightSlide,
      zoomImg
    } = this.state;

    return (
      <Slider
        slides={slides}
        activeSlide={activeSlide}
        leftSLide={leftSLide}
        rightSlide={rightSlide}
        zoomImg={zoomImg}
        pauseSlide={this.pauseSlide}
        playSlide={this.playSlide}
        handleClickLeftSlide={this.handleClickLeftSlide}
        handleClickRightSlide={this.handleClickRightSlide}
        handleZoomIn={this.handleZoomIn}
        renderZoomImg={this.renderZoomImg}
        sliderBodyStyle={styles.body}
      />
    );
  }
}

export default resizeDimension()(Reviews);
