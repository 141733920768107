import React, {Component} from 'react';
import { Helmet } from 'react-helmet';
// import InstagramEmbed from 'react-instagram-embed';

import NavSmall from '../Navs';
import Footer from '../Footer';

import Head from './Head';
import Items from './Items';


class Gallery extends Component {
  componentDidMount() {
    if (window.pageView) window.pageView('/gallery');
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" content="Галлерея работ с ремонтом вмятин, а также с процессом работы" />
          <meta name="keywords" content="ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина" />
          <title>Галлерея работ | Автосервис "SABR"</title>
        </Helmet>
        <NavSmall />
    
        <Head />
    
        <Items />

        {/* <div>
          <InstagramEmbed
            url='https://instagr.am/p/B0I3SpAjB2L/'
            maxWidth={640}
            hideCaption={false}
            containerTagName='div'
            protocol=''
            injectScript
            onLoading={() => {}}
            onSuccess={() => {}}
            onAfterRender={() => {}}
            onFailure={() => {}}
          />
        </div> */}
    
        <Footer />
      </div>
    );
  }
}

export default Gallery;
