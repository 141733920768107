export default [
    {
        name: 'Стандартный',
        subTitle: 'Курс для имеющих опыт работы по ремонту авто',
        days: 45,
        weeks: '1 неделя',
        teory: 5,
        samostRabota: 35,
        price: '35 000 руб.',
        priceC: '50 000 руб.',
        priceDouble: '27 000 руб.',
        tarif: '1',
    },
    {
        name: 'Расширенный',
        subTitle: 'Курс для тех кто разбирается в базовой теории',
        days: 90,
        weeks: '2 недели',
        teory: 10,
        samostRabota: 70,
        price: '55 000 руб.',
        priceC: '80 000 руб.',
        priceDouble: '40 000 руб.',
        tarif: '2',
    },
    {
        name: 'Максимальный',
        subTitle: 'Курс для новичков',
        days: 135,
        weeks: '3 недели',
        teory: 15,
        samostRabota: 110,
        price: '85 000 руб.',
        priceC: '110 000 руб.',
        priceDouble: '65 000 руб.',
        tarif: '3',
    },
];
