import React, { Component } from 'react';
import AOS from 'aos'; 

import Img from '../../../components/Img';

import description1 from './imgs/description-1.jpg';
import description1webp from './imgs/description-1.webp';
import description2 from './imgs/description-2.jpg';
import description2webp from './imgs/description-2.webp';

import styles from './styles.module.scss';

class Description extends Component {
  constructor(props, context) { 
    super(props, context); 
    AOS.init(); 
  } 
  componentWillReceiveProps (){ 
    AOS.refresh(); 
  } 

  render() {
    return (
      <div className={styles.container}>
        <div className={styles.row}>

          <div className={styles.colText}>
            <h3>Безупречное качество</h3>

            <p>
            Мы специализируемся на удалении вмятин без покраски в Казани по современной PDR технологии (Paintless Dent Repair). Это быстрый и эффективный способ полностью удалить различного рода вмятины, не нарушая заводское ЛКП.

            </p>
          </div>

          <div data-aos="zoom-out" data-aos-duration="500" className={styles.col}>
            <Img
              url={description1}
              urlWebp={description1webp}
              alt={"Безупречное качество"}
              width={'100%'}
              height={400}
            />
          </div>

        </div>
        <div className={styles.row}>

          <div data-aos="zoom-out" data-aos-duration="500" className={styles.col}>
            <Img
              url={description2}
              urlWebp={description2webp}
              alt={"Безупречное качество"}
              width={'100%'}
              height={400}
            />
          </div>

          <div className={styles.colText}>
            <h3>Профессионализм сотрудников</h3>

            <p>
            Все наши мастера прошли профессиональную подготовку по программе PDR и имеют большой практический опыт работы в направлении выпрямление, ремонт, удаление вмятин без покраски.
            Соответственно мы даём только достойный результат.

            </p>
          </div>

        </div>
      </div>
    );
  }
}

export default Description;
