import React from 'react';

import { API_URL } from '../../../../configs';

import styles from '../styles.module.scss';

import Form from '../Form';

const PhotoList = props => {
  const {
    uploadImgs,
    handleAddPhoto,
    handleDeletePhotos,
    goToBack,
    handleSubmit,
    isSuccessSend,
    onlyForm
  } = props;

  const uploadImgsKeys = Object.keys(uploadImgs);
  return (
    <div className={onlyForm ? styles.estimateTopFormDark : styles.estimateTopForm}>
      <div className={styles.photoList}>
      {uploadImgsKeys.map(i => {
      return (
          <div key={uploadImgs[i].imageURL} className={styles.photoListItem}>
            <img src={`${API_URL}/upload/${uploadImgs[i].imageURL}`}  alt=""/>
            <button
              className={styles.deleteBtn}
              onClick={() => handleDeletePhotos(uploadImgs[i].imageURL, i)}
            />
          </div>
      )})}

      {uploadImgsKeys.length < 4 && !onlyForm && (
        <button onClick={() => false} className={styles.downloadBtn2}>
          <input
            type="file"
            onChange={handleAddPhoto}
          />
        </button>
      )}
    </div>

      {onlyForm && <h4 style={{ color: '#fff'}}>Напишите нам!</h4>}
    <Form
      goToBack={goToBack}
      handleSubmit={handleSubmit}
      isSuccessSend={isSuccessSend}
    />
  </div>
  );
}

export default PhotoList;
