import React, { Component } from 'react';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';
import prices from './prices';

class Header extends Component {
  render() {
    const {
      handleChangeTarif,
    } = this.props;
    return (
      <div className={styles.fullContainer}>
        <div className={styles.head}>
          <div className={styles.headWrapper}>
            <h3 className={styles.headTitle}>Программа обучения</h3>
          </div>
        </div>

        <div className={styles.body}>
          <div className={styles.tarifs}>
            {prices.map((i, key) => (
              <div key={`tarifItem${key}`} className={styles.tarifsItem}>
                <div className={styles.tarifsItemHead}>
                  <p>{i.name}</p>
                  <span>{i.subTitle}</span>
                </div>
                <div className={styles.tarifsItemBody}>
                  <ul>
                    {/* <li>{i.days} часов</li> */}
                    <li>{i.weeks}</li>
                    <li>{i.teory} ч. теоретических занятий</li>
                    <li>{i.samostRabota} часов практика</li>
                    {/* <li>Практика на учебных элементах</li> */}
                    <li>Проживание в Казани</li>
                  </ul>

                  <div className={styles.price}>
                    <span>{i.price}</span> <del>{i.priceC}</del>
                  </div>

                  <div className={styles.doubleprice}>
                    <p>{i.priceDouble}</p>
                    <span>цена за друга*</span>
                  </div>
                </div>
                <div className={styles.tarifsItemBtn}>
                <HashLink
                  smooth
                  to="/learning#cta-block"
                  onClick={() => {
                    if (window.reachGoal) window.reachGoal('click_free_estimate');
                    handleChangeTarif(i.tarif);
                  }}
                  className={styles.btn}
                >
                  <span>Выбрать программу</span>
                </HashLink>
                </div>
              </div>
            ))}
            
          </div>

          <div className={styles.tarifsInfo}>* - скидка распространяется на стоимость обучения второго ученика</div>
        </div>
      </div>
    );
  }
}

export default Header;
