import React from 'react';

import Img from '../../Img';

import styles from '../styles.module.scss';

const SlideItem = ({
  isActive,
  isRightSlide,
  urls,
  name,
  position,
  desc,
  handleClick = () => false,
}) => (
  <div
    onClick={handleClick}
    className={isActive ? styles.slideActiveItem : isRightSlide ? styles.slideRightItem : styles.slideItem}
  >
    <div className={styles.slidePhoto}>
        <Img
          url={urls}
          width='100%'
          height='100%'
        />
    </div>
    {desc && <div className={styles.slideDesc}>
        <p>{desc}</p>
    </div>}
  </div>
);

export default SlideItem;
