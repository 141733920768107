import React from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import InputMask from 'react-input-mask';
import tarifs from '../../Tarifs/prices'

import styles from './styles.module.scss';

const Form = ({
  phone,
  name,
  tarif,
  checkPolicy,
  handleSubmit,
  isSuccessSend,
  handleChange,
  handleCheck,
}) => {
    const formatTarifs = tarifs.map(i => ({
      title: `Тариф "${i.name}" - ${i.price}`,
      value: i.tarif,
    }));

    return (
      <div className={styles.formSend}>
        <ReCaptcha
            sitekey="6Ld3xa4UAAAAAPJpVGfwynLZ55nf25ZeZx7QFPo7"
            action='estimate'
        />
        <form
        onSubmit={e => handleSubmit(e, {
          contact: phone,
          name,
          tarif: formatTarifs,
        })}
        method="POST"
        encType="multipart/form-data"
        >
          <div className={styles.formGroup}>
          <InputMask
            mask="+7 (999) 999-99-99" 
            placeholder="Номер телефона" 
            name="phone" 
            type="tel"
            value={phone} 
            className={styles.textField}
            onChange={handleChange}
          />
            
          </div>
          <div className={styles.formGroup}>
            <input
              name="name"
              value={name}
              className={styles.textField}
              placeholder="Ваше имя"
              onChange={handleChange}
            />
          </div>
          <div className={styles.formGroup}>
            <select
              name="tarif"
              value={tarif}
              className={styles.textField}
              placeholder="Выберите тариф"
              onChange={handleChange}
            >
              {formatTarifs.map((i, key) => (
                <option key={`tarif${key}`} value={i.value}>{i.title}</option>
              ))}
              </select>
          </div>
          <div className={styles.formGroup}>
            <input
              type="checkbox"
              name="checkPolicy"
              id="checkPolicy"
              className={styles.checkbox}
              onChange={handleCheck}
              defaultChecked={checkPolicy}
            />
            <label htmlFor="checkPolicy">Я даю согласие на обработку персональных данных.</label>
          </div>

          <div className={styles.formBtnGroup}>
            <input
              type="submit"
              value={isSuccessSend ? "Заявка отправлена!" : "Отправить заявку"}
              className={styles.submitBtn}
              disabled={!checkPolicy || !phone || !tarif}
            />
          </div>
          <div className={styles.formGroup}>
            <label className={styles.recaptcha} htmlFor="">Сайт защищен reCAPTCHA и Google <a href="https://policies.google.com/privacy">Политика конфиденциальности</a> и <a href="https://policies.google.com/terms">Условия обслуживания</a> применяются.</label>
          </div>
        </form>
      </div>
    );
  }

export default Form;
