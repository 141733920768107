import React, { Component } from 'react';

import bgJpg from '../Items/imgs/bg.jpg';

import HeadComponent from '../../../../components/Head';

class Header extends Component {
  render() {
    return (
      <HeadComponent
        bgJpg={bgJpg}
        bgWebp={null}
        title="Процесс обучения"
      />
    );
  }
}

export default Header;
