import React from 'react';
import { HashLink } from 'react-router-hash-link';

import styles from './styles.module.scss';

const CtaBtns = () => (
  <div className={styles.ctaBtns}>
            <HashLink
              to="/learning#cta-block"
              // onClick={() => window.reachGoal('click_free_estimate')}
              className={styles.btnRight}
              smooth
            >
              <span>Записаться на обучение</span>
            </HashLink>
            <button className={styles.btnLeft} onClick={() => {
              if (window.reachGoal) window.reachGoal('whatsapp_call');
              window.open('https://api.whatsapp.com/send?phone=79872289904&text=%D0%AF%20%D1%85%D0%BE%D1%87%D1%83%20%D0%B7%D0%B0%D0%BF%D0%B8%D1%81%D0%B0%D1%82%D1%8C%D1%81%D1%8F%20%D0%BD%D0%B0%20%D0%BE%D0%B1%D1%83%D1%87%D0%B5%D0%BD%D0%B8%D0%B5!','_blank');
            }} rel="noopener noreferrer">
              Записаться в WhatsApp
            </button>
          </div>
);

export default CtaBtns;
