const uuidv4 = require('uuid/v4');

export const generateUuid = () => uuidv4();

export function hasWebp() {
  var elem = document.createElement('canvas');

  if (!!(elem.getContext && elem.getContext('2d'))) {
      // was able or not to get WebP representation
      return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }

  // very old browser like IE 8, canvas not supported
  return false;
}

export function makeid(length) {
  var result           = '';
  var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
     result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const getUserId = () => {
  let userId;
  if (localStorage.getItem('userId')) {
    userId = localStorage.getItem('userId');
  } else {
    userId = generateUuid();
    localStorage.setItem('userId', userId);
  }

  return userId;
}

export const refreshUserId = () => {
  const userId = generateUuid();
  localStorage.setItem('userId', userId);

  return userId;
}