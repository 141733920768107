import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Link as RouterLink,
  useLocation,
} from 'react-router-dom';
import styles from '../styles.module.scss';

const NavLink = ({ to, children, hash = '' }) => {
  let { pathname } = useLocation();
  const Link = hash ? HashLink : RouterLink;
  return (
    <Link
      className={pathname === to ? styles.navLinkActive : styles.navLink}
      to={`${to}${hash}`}
    >
        {children}
    </Link>
  );
}

export default NavLink;
