import React, {Component} from 'react';
import { Helmet } from 'react-helmet';

import NavSmall from '../Navs';
import CtaBtns from '../../components/CtaBtns';
import Footer from '../Footer';

import Head from './Head';

import almazImg from './imgs/almaz.jpeg';
import azatImg from './imgs/azat.jpeg';
import ilyasImg from './imgs/ilyas.jpeg';
import rashidImg from './imgs/rashid.jpeg';
import vladImg from './imgs/vlad.jpeg';

import styles from './styles.module.scss';


class OurTeam extends Component {
  componentDidMount() {
    if (window.pageView) window.pageView('/our-team');
  }

  render() {
    return (
      <div>
        <Helmet>
          <meta name="description" content="Наша команда, которая обслуживает вас на протяжение всего процесса ремонта или доработки вашего автомобиля. " />
          <meta name="keywords" content="список услуг, услуги автосервиса, ремонт кузова, инструменты для ремонта, красить машину, покраска машины, ремонт вмятин,  вмятины на машине, pdr, вмятина, машина, компания по ремонту вмятин, наша команда" />
          <title>Наша команда | Автосервис "SABR"</title>
        </Helmet>
        <NavSmall />

        <Head />
    
        <div className={styles.mainWrapper}>
          <div className={styles.container}>
            {/* <h1></h1> */}
            <div className={styles.team}>
              <div className={styles.teamItem}>
                <img src={ilyasImg} alt="Ильяс - основатель автосервиса SABR"/>
                <div className={styles.teamItemText}><b>Ильяс</b><span>основатель автосервиса SABR</span></div>
              </div>
            </div>
            <div className={styles.team}>
              <div className={styles.teamItem}>
                <img src={almazImg} alt="Алмаз - мастер по ремонту вмятин без покраски"/>
                <div className={styles.teamItemText}><b>Алмаз</b><span>мастер по ремонту вмятин</span> </div>
              </div>
              <div className={styles.teamItem}>
                <img src={rashidImg} alt="Рашид - мастер по ремонту вмятин без покраски"/>
                <div className={styles.teamItemText}><b>Рашид</b><span>мастер по ремонту вмятин</span></div>
              </div>
              <div className={styles.teamItem}>
                <img src={azatImg} alt="Азат - мастер по ремонту вмятин без покраски"/>
                <div className={styles.teamItemText}><b>Азат</b><span>мастер по ремонту вмятин</span> </div>
              </div>
              <div className={styles.teamItem}>
                <img src={vladImg} alt="Влад - мастер по ремонту вмятин без покраски"/>
                <div className={styles.teamItemText}><b>Влад</b><span>мастер по ремонту вмятин</span></div>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.ctaBtns}>
          <CtaBtns />
        </div>
        
    
        <Footer />
      </div>
    );
  }
}

export default OurTeam;
