import React, { Component } from 'react';

import Logo from '../../../components/Logo';
import Modal from '../../../components/Modal';
import CtaBtns from '../../../components/CtaBtns';

import resizeDimension from '../../../decorators/resizeDimension';

import styles from './styles.module.scss';
import video from './video.mp4';

class Header extends Component {
  constructor() {
    super();
    this.state = {
      openModal: false,
    };
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.closeModal();
    }
  }

  openModal = () => {
    if (window.reachGoal) window.reachGoal('view_head_video');
    document.addEventListener('keydown', this.escFunction, false);
    this.setState({
      openModal: true,
    });
  }
  closeModal = () => {
    this.setState({
      openModal: false,
    });
    document.removeEventListener('keydown', this.escFunction, false);
  }

  handleScrollNext = () => {
    const nextBlock = document.getElementById('next-block');
    const y = nextBlock.getBoundingClientRect().top;
    window.scrollBy({ 
      top: y,
      left: 0, 
      behavior: 'smooth' 
    });
  }

  renderVideo = () => {
    const { openModal } = this.state;
    // const videoUrl = 'https://www.youtube.com/embed/WaEkz6S5X8o?rel=0&amp;showinfo=0';
    return (
      <div className={openModal ? styles.modalFullScreenIsOpen : styles.modalFullScreen}>
        <div
          className={styles.modalCloseBtn}
          onClick={this.closeModal}
        >
          <svg viewBox="0 0 24 24" preserveAspectRatio="xMidYMid meet" focusable="false" className="style-scope yt-icon"><g className="style-scope yt-icon">
            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" className="style-scope yt-icon" />
          </g></svg>
        </div>
        <video
            src={video}
            height="100%"
            width="100%"
            type="video/mp4"
            controls
            autoPlay
          />
      </div>
    );
  }

  render() {
    const { openModal } = this.state;
    const { innerHeight } = this.props;
    return (
      <header id="header-block" className={styles.header} style={{ height: innerHeight }}>
        <div className={styles.headerWrapper}>
          <div className={styles.logo}>
            <Logo />
          </div>
          <h1 className={styles.title}>
            Ремонт вмятин без нарушения ЛКП от 500 руб. за 30 минут
          </h1>
          <h4 className={styles.subtitle}>Удаление вмятин по современной технологии PDR</h4>
          {/*<button onClick={this.openModal} className={styles.playBtn}>смотреть видео</button>*/}

          <CtaBtns />

          <button onClick={this.handleScrollNext} className={styles.mouse} >
            <svg width="40px" height="100%" viewBox="0 0 247 390" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <path className={styles.wheel} d="M123.359,79.775l0,72.843"/>
              <path className={styles.mouseBord} d="M236.717,123.359c0,-62.565 -50.794,-113.359 -113.358,-113.359c-62.565,0 -113.359,50.794 -113.359,113.359l0,143.237c0,62.565 50.794,113.359 113.359,113.359c62.564,0 113.358,-50.794 113.358,-113.359l0,-143.237Z"/>
            </svg>
          </button>
        </div>


        {openModal && <Modal>
          {this.renderVideo()}
        </Modal>}
      </header>
    );
  }
}

export default resizeDimension()(Header);
